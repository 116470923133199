<template>
    <v-container class="pt-10">
        <v-row justify="center">
            <v-col xl="9">
                <full-screen-loader v-if="fetchingUserInfo || fetchingOrgs" />
                <div v-else-if="troubleshootList">
                    <v-alert type="warning" text prominent v-for="help in troubleshootList" :key="help.reqid">
                        <div v-html="help.msg"></div>
                    </v-alert>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapState } from 'vuex'
import { intercomMethods } from '@/mixins/intercom'
const FullScreenLoader = () => import('@/views/TheFullScreenLoader.vue')

export default {
    mixins: [intercomMethods],
    data() {
        return {
            loginMethod: null,
            troubleshootList: null
        }
    },
    computed: {
        ...mapState(['currentOrgData', 'fetchingOrgs', 'userInfo', 'fetchingUserInfo']),
        callTroubleshoot() {
            if (!this.fetchingOrgs && !this.fetchingUserInfo) {
                return !this.userInfo || !this.currentOrgData || !this.loginMethod
            } else {
                return false
            }
        }
    },
    watch: {
        currentOrgData: {
            handler(value) {
                if (value) {
                    this.$router.push({
                        name: 'home-dashboard',
                        params: { oid: value.oid }
                    })
                }
            },
            immediate: true
        },
        callTroubleshoot: {
            handler(missing) {
                if (!missing) return
                this.troubleshoot()
            },
            immediate: true
        }
    },
    created() {
        this.$axios
            .post('/users/login_methods', { email: this.$auth.user.email })
            .then(response => {
                if (response?.data?.methods?.length) {
                    this.loginMethod = response.data.methods
                } else {
                    this.loginMethod = null
                }
            })
            .catch(() => {
                this.loginMethod = null
            })
    },
    methods: {
        async troubleshoot() {
            try {
                const { data } = await this.$axios.get(`/users/troubleshoot`)
                this.troubleshootList = data
                const contactSupportIndex = this.troubleshootList.findIndex(help => {
                    return help.code === 'contact_support'
                })
                if (contactSupportIndex !== -1 && this.$appConfig.VUE_APP_INTERCOM_ID) {
                    this.showNewMessageIntercom(
                        `Hi, \n\nI'm having problems with my account on Nuvolos: \n${this.troubleshootList[contactSupportIndex].msg} \n\nTroubleshoot id: ${this.troubleshootList[contactSupportIndex].reqid} \n\nI intend to do the following on Nuvolos:`
                    )
                }
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Could not troubleshoot.',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
        }
    },
    components: {
        FullScreenLoader
    }
}
</script>
